<template>
  <v-container class="mt-1">
    <v-sheet class="mx-auto pa-4" max-width="600">
      <!-- Language switcher -->
      <!-- <v-row>
        <v-col cols="12" class="text-right">
          <select v-model="selectedLocale" @change="localeChange" class="text-right">
            <option v-for="option in localeOptions" :key="option.value" :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </v-col>
      </v-row> -->

      <!-- Page title -->
      <h1 class="mx-auto text-center text-green-lighten-1">Hikers Roundtable</h1>
      <h1 class="mx-auto text-center text-green-lighten-1">{{ $t('registration_title') }}</h1>

      <!-- Event poster -->
      <v-img width="600" class="py-3" :src="event.poster"></v-img>

      <!-- Event details -->
      <v-row class="mt-1">
        <v-col cols="2" class="ml-10">
          <span class="font-weight-bold text-green-lighten-1 mr-4">{{ $t('event')
            }}:</span>
        </v-col>
        <v-col cols="8">
          <span>{{ event.name }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10">
          <span class="font-weight-bold text-green-lighten-1 mr-4">{{ $t('date')
            }}:</span>
        </v-col>
        <v-col cols="8">
          <span>{{ eventDate }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="ml-10">
          <span class="font-weight-bold text-green-lighten-1 mr-4">{{ $t('time')
            }}:</span>
        </v-col>
        <v-col cols="8">
          <span>{{ eventTime }}</span>
        </v-col>
      </v-row>

      <!-- Registration form -->
      <v-form v-model="formIsValid" class="mt-8" @submit.prevent="handleSubmit">
        <v-row>
          <v-col cols="12">
            <p class="mb-2 font-weight-bold">{{ $t('category') }} <span class="text-red-lighten-2">*</span></p>
            <v-select :items="categories" density="compact" variant="outlined" :rules="categoryRules"
              :placeholder="$t('placeholder_categories')" @update:model-value="updateSelectedCategory" required>
            </v-select>
          </v-col>
        </v-row>

        <v-row v-if="isGroup">
          <v-col cols="12">
            <p class="mb-2 font-weight-bold">{{ $t('participant_group') }} <span class="text-red-lighten-2">*</span></p>
            <v-select :items="hikersGroupName" density="compact" variant="outlined" :rules="hikerGroupRules"
              @update:model-value="updateSelectedGroup" required>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <p class="mb-2 font-weight-bold">{{ $t('name') }} <span class="text-red-lighten-2 mr-3">*</span>
            </p>
            <v-text-field v-model="participantName" density="compact" variant="outlined" :rules="nameRules" required
              :placeholder="$t('placeholder_name')">
            </v-text-field>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12">
            <p class="mb-2 font-weight-bold">{{ $t('nickname') }} <span class="text-red-lighten-2 mr-3">*</span>
            </p>
            <v-text-field v-model="participantNickname" density="compact" variant="outlined" :rules="nicknameRules"
              required :placeholder="$t('placeholder_nickname')">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <p class="mb-2 font-weight-bold">{{ $t('nationality') }} <span class="text-red-lighten-2">*</span></p>
            <v-autocomplete :items="countriesName" density="compact" variant="outlined" :rules="nationalityRules"
              required :placeholder="$t('placeholder_nationality')" @update:model-value="updateSelectedCountry">
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <p class="mb-2 font-weight-bold">{{ $t('passport') }} <span class="text-red-lighten-2 mr-4">*</span></p>
            <v-text-field v-model="icPassport" density="compact" variant="outlined" required :rules="idRules"
              placeholder="xxxxxxxxxxxx">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <p class="mb-2 font-weight-bold">{{ $t('dob') }} <span class="text-red-lighten-2">*</span>
            </p>
            <v-input class="v-input-custom" :model-value="dob" :rules="dobRules" :label="$t('dob')">
              <template v-slot:default="{ props }">
                <VueDatePicker v-bind="props" :model-value="dob" :format="formatDate" @update:model-value="updateDate"
                  :placeholder="dobPlaceHolder" :enable-time-picker="false" auto-apply />
              </template>
            </v-input>
          </v-col>

          <v-col cols="6">
            <p class="mb-2 font-weight-bold">{{ $t('gender') }} <span class="text-red-lighten-2 mr-4">*</span></p>
            <v-select :items="genders" density="compact" variant="outlined" :rules="genderRules" required
              item-title="display" item-value="value" :placeholder="$t('placeholder_gender')"
              @update:model-value="updateSelectedGender">
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" style="margin-bottom: -1rem;">
            <p class="font-weight-bold">{{ $t('phone') }} <span class="text-red-lighten-2 mr-4">*</span>
            </p>

            <v-input class="v-input-custom mt-3 mb-9" :model-value="userPhoneNumber" :rules="userPhoneRules"
              :label="$t('phone')" required>
              <template v-slot:default>
                <vue-tel-input class="w-100" defaultCountry="MY" v-model="userPhoneNumber"
                  @validate="(validation) => userPhoneValidation = validation" validCharactersOnly
                  :inputOptions="{ placeholder: $t('placeholder_phone'), required: true, showDialCode: true }" />
              </template>
            </v-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <p class="mb-2 font-weight-bold">{{ $t('email') }} <span class="text-red-lighten-2 mr-4">*</span></p>
            <v-text-field density="compact" v-model="userEmail" variant="outlined" placeholder="johndoe@email.com"
              required :rules="emailRules">
            </v-text-field>
          </v-col>
        </v-row>

        <v-sheet class="mt-8 border-thin rounded-t">
          <p class="bg-green-darken-1 pa-2 rounded-t">{{ $t('emergency_contact') }}</p>

          <v-row class="pa-4">
            <v-col cols="12">
              <v-row>
                <v-col>
                  <h3>{{ $t('person') }} 1</h3>
                  <p class="my-3 font-weight-bold">{{ $t('name') }} <span class="text-red-lighten-2 mr-3">*</span>
                  </p>
                  <v-text-field v-model="firstContactName" density="compact" variant="outlined" :rules="nameRules"
                    required>
                  </v-text-field>
                  <p class="my-3 font-weight-bold">{{ $t('phone') }} <span class="text-red-lighten-2 mr-4">*</span></p>
                  <v-input class="v-input-custom mt-3 mb-9" :model-value="firstContactNumber"
                    :rules="firstContactPhoneRules" :label="$t('phone')" required>
                    <template v-slot:default>
                      <vue-tel-input class="w-100" defaultCountry="MY" v-model="firstContactNumber"
                        @validate="(validation) => firstContactPhoneValidation = validation" validCharactersOnly
                        :inputOptions="{ placeholder: $t('placeholder_phone'), required: true, showDialCode: true }" />
                    </template>
                  </v-input>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col>
                  <h3>{{ $t('person') }} 2</h3>
                  <p class="my-3 font-weight-bold">{{ $t('name') }} <span class="text-red-lighten-2 mr-3">*</span>
                  </p>
                  <v-text-field v-model="secondContactName" required :rules="nameRules" density="compact"
                    variant="outlined">
                  </v-text-field>
                  <p class="my-3 font-weight-bold">{{ $t('phone') }} <span class="text-red-lighten-2 mr-4">*</span></p>
                  <v-input class="v-input-custom mt-3 mb-9" :model-value="secondContactNumber"
                    :rules="secondContactPhoneRules" :label="$t('phone')" required>
                    <template v-slot:default>
                      <vue-tel-input class="w-100" defaultCountry="MY" v-model="secondContactNumber"
                        @validate="(validation) => secondContactPhoneValidation = validation" validCharactersOnly
                        :inputOptions="{ placeholder: $t('placeholder_phone'), required: true, showDialCode: true }" />
                    </template>
                  </v-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>

        <v-row class="mt-10">
          <v-col cols="12">
            <v-checkbox v-model="isFirstConsentGiven" :rules="consentRules" :label="$t('participation_consent')">
            </v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="isSecondConsentGiven" :rules="consentRules" :label="$t('tnc_consent')">
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" md="8">
            <v-btn class="mt-2" type="submit" variant="outlined" color="success" block>
              {{ $t('submit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>

    <!-- Form invalid dialog -->
    <v-dialog v-model="formInvalidDialog" width="auto">
      <v-card class="rounded-lg" max-width="400" :title="$t('form_incomplete')">
        <v-card-text>
          {{ $t('form_invalid_message') }}
        </v-card-text>
        <template v-slot:actions>
          <v-btn class="ms-auto" color="green" min-width="80px" variant="flat" :text="$t('ok')"
            @click="formInvalidDialog = !formInvalidDialog">
          </v-btn>
        </template>
      </v-card>
    </v-dialog>

    <!-- Form details confirmation dialog -->
    <v-dialog v-model="formConfirmationDialog" width="auto">
      <v-card class="rounded-lg" max-width="500" :title="$t('form_confirmation')">
        <v-card-text>
          <p>
            {{ $t('form_confirmation_message') }}
          </p>
          <br>
          <v-table density="comfortable">
            <tr>
              <td>{{ $t('category') }}:</td>
              <td><strong>{{ selectedCategory }}</strong></td>
            </tr>
            <tr v-if="isGroup">
              <td>{{ $t('participant_group') }}:</td>
              <td><strong>{{ selectedGroup }}</strong></td>
            </tr>
            <tr>
              <td>{{ $t('name') }}:</td>
              <td><strong>{{ participantName }}</strong></td>
            </tr>
            <tr>
              <td>{{ $t('nickname') }}:</td>
              <td><strong>{{ participantNickname }}</strong></td>
            </tr>
            <tr>
              <td>{{ $t('nationality') }}:</td>
              <td><strong>{{ selectedCountry }}</strong></td>
            </tr>
            <tr>
              <td>{{ $t('passport') }}:</td>
              <td><strong>{{ icPassport }}</strong></td>
            </tr>
            <tr>
              <td>{{ $t('dob') }}:</td>
              <td><strong>{{ dob }}</strong></td>
            </tr>
            <tr>
              <td>{{ $t('gender') }}:</td>
              <td><strong>{{ selectedGender }}</strong></td>
            </tr>
            <tr>
              <td>{{ $t('phone') }}:</td>
              <td><strong>{{ userPhoneNumber.replaceAll(' ', '') }}</strong></td>
            </tr>
            <tr>
              <td>{{ $t('email') }}:</td>
              <td><strong>{{ userEmail }}</strong></td>
            </tr>
            <tr>
              <td>{{ $t('emergency_contact') }} 1:</td>
              <td><strong>{{ firstContactName }} ({{ firstContactNumber.replaceAll(' ', '') }})</strong></td>
            </tr>
            <tr>
              <td>{{ $t('emergency_contact') }} 2:</td>
              <td><strong>{{ secondContactName }} ({{ secondContactNumber.replaceAll(' ', '') }})</strong></td>
            </tr>
          </v-table>
        </v-card-text>
        <v-card-text>
        </v-card-text>
        <template v-slot:actions>
          <v-btn color="green mx-3" min-width="80px" variant="flat" :text="$t('submit')" @click="submitForm">
          </v-btn>
          <v-btn :text="$t('back')" min-width="80px" variant="tonal"
            @click="formConfirmationDialog = !formConfirmationDialog">
          </v-btn>
        </template>
      </v-card>
    </v-dialog>

    <!-- Error dialog -->
    <v-dialog v-model="errorDialog" width="auto">
      <v-card class="rounded-lg" max-width="400" :title="$t('error')">
        <v-card-text>
          {{ $t('error_message') }}
        </v-card-text>
        <template v-slot:actions>
          <v-btn class="ms-auto" :text="$t('ok')" @click="errorDialog = !errorDialog">
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script setup>
useHead({
  title: 'Event Registration',
})
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { format, parse } from 'date-fns'

const { locale, setLocale, t } = useI18n();
const route = useRoute()
const { slug } = route.params
const { data: event } = await useFetch(`/api/v1/web/events/${slug}`)
const eventId = event.value.id

const eventDate = event.value.date ? format(new Date(event.value.date), 'EEEE, dd MMMM yyyy') : 'No date provided'

let fromTime, toTime, fromTimeFormatted, toTimeFormatted, eventTime

if (event.value.fromTime && event.value.fromTime) {
  fromTime = parse(event.value.fromTime, 'HH:mm:ss', new Date())
  toTime = parse(event.value.toTime, 'HH:mm:ss', new Date())

  fromTimeFormatted = format(fromTime, 'HH:mm aa')
  toTimeFormatted = format(toTime, 'HH:mm aa')

  eventTime = `${fromTimeFormatted} - ${toTimeFormatted}`
} else {
  eventTime = 'No time provided'
}

const selectedLocale = ref(locale.value);

watch(locale, (newValue) => {
  selectedLocale.value = newValue;
});

const localeChange = (event) => {
  const newLocale = event.target.value;
  setLocale(newLocale);
};

const localeOptions = [
  { value: 'en', label: 'English' },
  { value: 'ms', label: 'Bahasa Melayu' },
  { value: 'zh-CN', label: '中文' }
];

const hikersGroup = ref([])
const hikersGroupName = ref([])
const countriesName = ref([])
onMounted(async () => {
  const countryList = await $fetch(`https://restcountries.com/v3.1/all`)
  countriesName.value = countryList.map(country => country.name.common).sort()

  const hikersGroupData = await $fetch('/api/v1/web/hiker_groups')
  hikersGroup.value = hikersGroupData
  hikersGroupName.value = hikersGroupData.map(group => group.name)
})

const formIsValid = ref(false)
const selectedCategory = ref('')
const selectedGroup = ref('')
const participantName = ref('')
const participantNickname = ref('')
const selectedCountry = ref('')
const icPassport = ref('')
const dob = ref(null)
const selectedGender = ref('')
const userPhoneNumber = ref(null)
const userEmail = ref('')
const firstContactName = ref('')
const secondContactName = ref('')
const firstContactNumber = ref('')
const secondContactNumber = ref('')
const isFirstConsentGiven = ref(false)
const isSecondConsentGiven = ref(false)

const formInvalidDialog = ref(false)
const formConfirmationDialog = ref(false)
const errorDialog = ref(false)

const dobPlaceHolder = "yyyy-mm-dd"
const formatDate = (date) => {
  return format(date, 'yyyy-MM-dd')
}

const categories = ref(['Individual', 'Marshall', 'HikerGroup'])

const categoryRules = [
  value => {
    if (value) return true

    return t('category_required')
  }
]

const hikerGroupRules = [
  value => {
    if (!isGroup) return true
    if (value) return true

    return t('participant_group_required')
  }
]

const nameRules = [
  value => {
    if (value) return true

    return t('name_required')
  }
]

const nicknameRules = [
  value => {
    if (value) return true

    return t('nickname_required')
  }
]

const nationalityRules = [
  value => {
    if (value) return true

    return t('nationality_required')
  }
]


const idRules = [
  value => {
    if (value) return true

    return t('id_required')
  }
]


const genderRules = [
  value => {
    if (value) return true

    return t('gender_required')
  }
]

const dobRules = [
  value => {
    if (value) return true

    return t('dob_required')
  }
]

const userPhoneValidation = ref(null)
const userPhoneRules = [
  () => {
    if (!userPhoneValidation.value) return true
    if (userPhoneValidation.value.formatted === '') return true
    if (userPhoneValidation.value.valid) return true

    return t('phone_invalid')
  }
]

const firstContactPhoneValidation = ref(null)
const firstContactPhoneRules = [
  () => {
    if (!firstContactPhoneValidation.value) return true
    if (firstContactPhoneValidation.value.formatted === '') return true
    if (firstContactPhoneValidation.value.valid) return true

    return t('phone_invalid')
  }
]

const secondContactPhoneValidation = ref(null)
const secondContactPhoneRules = [
  () => {
    if (!secondContactPhoneValidation.value) return true
    if (secondContactPhoneValidation.value.formatted === '') return true
    if (secondContactPhoneValidation.value.valid) return true

    return t('phone_invalid')
  }
]

const emailRules = [
  value => {
    if (value) return true

    return t('email_required')
  },
  value => {
    if (/.+@.+\..+/.test(value)) return true

    return t('email_invalid')
  },
]

const consentRules = [
  value => {
    if (value) return true

    return t('consent_required')
  }
]

const isGroup = computed(() => {
  return (selectedCategory.value === 'HikerGroup' || selectedCategory.value === 'Marshall') ? true : false
})

const genders = [
  { value: "Male", display: t("male") },
  { value: "Female", display: t('female') }
]

function updateSelectedCategory(value) {
  selectedCategory.value = value
}

function updateSelectedGroup(value) {
  selectedGroup.value = value
}

function updateSelectedCountry(value) {
  selectedCountry.value = value
}


function updateDate(value) {
  dob.value = format(new Date(value), 'yyyy-MM-dd')
}

function updateSelectedGender(value) {
  selectedGender.value = value
}

async function submitForm() {
  const participantInfo = {
    user: {
      name: participantName.value,
      nickname: participantNickname.value,
      email: userEmail.value,
      phoneNumber: userPhoneNumber.value.replaceAll(' ', ''),
      identityNumber: icPassport.value,
      nationality: selectedCountry.value,
      gender: selectedGender.value,
      dateOfBirth: dob.value,
      emergencyContactName1: firstContactName.value,
      emergencyContactPhoneNumber1: firstContactNumber.value.replaceAll(' ', ''),
      emergencyContactName2: secondContactName.value,
      emergencyContactPhoneNumber2: secondContactNumber.value.replaceAll(' ', ''),
      hikerGroupId: selectedGroup.value === '' ? null : hikersGroup.value.find(group => group.name === selectedGroup.value).id
    },
    eventId: eventId,
    category: selectedCategory.value
  }

  try {
    const { id } = await $fetch('/api/v1/web/events/registration', {
      method: 'POST',
      body: participantInfo
    })
    await navigateTo({ path: `/events/confirmation/${id}` });
  } catch (error) {
    formConfirmationDialog.value = false
    errorDialog.value = true
  }
}

function handleSubmit() {
  if (!formIsValid.value) {
    formInvalidDialog.value = true
  } else {
    formConfirmationDialog.value = true
  }
}
</script>

<style scoped>
:deep(.dp__input) {
  border: 1px solid rgba(128, 128, 128, 0.623);
}

:deep(.vue-tel-input) {
  height: 40px;
}

:deep(.v-input--error .vue-tel-input),
:deep(.v-input--error .dp__input) {
  border-color: rgb(var(--v-theme-error));
}

:deep(.vue-tel-input):focus-within,
:deep(.dp__input):focus-within {
  box-shadow: none;
  border-color: rbg(var(--light-dark));
  outline-color: rgba(128, 128, 128, 0.623);
}

.v-input-custom :deep(.v-messages) {
  margin-left: 12px;
}

.v-checkbox :deep(.v-messages) {
  margin-left: 40px;
}
</style>
